import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "reactstrap";
import axios from "axios";
import { authHeader } from "../../../../services/fack.backend";
import Select from "react-select"; // Certifique-se de ter instalado react-select

const FilterBilling = (props) => {
    const { setFilterOnAppointments, setIsFilter } = props;
    const [isLoadingFilters, setIsLoadingFilters] = useState(true);
    const [healthInsurances, setHealthInsurances] = useState([]);
    const [patients, setPatients] = useState([]);
    const [doctors, setDoctors] = useState([]);
    const [clinics, setClinics] = useState([]);
    const [filters, setFilters] = useState([
        {
            field: '',
            value: ''
        }
    ]);

    const filterFields = [
        { value: 'appointmentType', description: 'Tipo de Consulta' },
        { value: 'status', description: 'Status' },
        { value: 'guideNumber', description: 'Número do Guia' },
        { value: 'health_insurance_id', description: 'Convênio' },
        { value: 'patient_id', description: 'Paciente' },
        { value: 'doctor_id', description: 'Médico' },
        { value: 'clinic_id', description: 'Clínica' },
        { value: 'date', description: 'Data' }
    ];

    useEffect(() => {
        fetchClinics();
        fetchDoctors();
        fetchHealthInsurances();
        fetchPatients();

        const savedFilters = localStorage.getItem('billingFilters');
        if (savedFilters)
            setFilters(JSON.parse(savedFilters));

        setIsLoadingFilters(false);
    }, []);

    const saveFilters = (filters) => {
        localStorage.setItem('billingFilters', JSON.stringify(filters));
    }

    const fetchClinics = async () => {
        setIsLoadingFilters(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clinic/getClinics`, { headers: authHeader() });
            setClinics(response.data);
        } catch (error) {
            console.error('Error fetching clinics:', error);
        } finally {
            setIsLoadingFilters(false);
        }
    };

    const fetchDoctors = async () => {
        setIsLoadingFilters(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/doctor/all`, { headers: authHeader() });
            const sortedDoctors = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setDoctors(sortedDoctors);
        } catch (error) {
            console.error('Error fetching doctors:', error);
        } finally {
            setIsLoadingFilters(false);
        }
    };

    const fetchHealthInsurances = async () => {
        setIsLoadingFilters(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/healthInsurance/getAll`, { headers: authHeader() });
            setHealthInsurances(response.data);
        } catch (error) {
            console.error('Error fetching health insurances:', error);
        } finally {
            setIsLoadingFilters(false);
        }
    };

    const fetchPatients = async () => {
        setIsLoadingFilters(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/patient/getPatients`, { headers: authHeader() });
            setPatients(response.data);
        } catch (error) {
            console.error('Error fetching patients:', error);
        } finally {
            setIsLoadingFilters(false);
        }
    };

    const clearFilter = () => {
        setIsFilter(false);
        setFilters([]);
    };

    const createFilterRow = () => {
        setFilters(currentFilters => [...currentFilters, { field: '', value: '' }]);
    };

    const deleteFilterRow = (index) => {
        setFilters(currentFilters => currentFilters.filter((_, idx) => idx !== index));
    };

    const setFilterField = (value, index) => {
        setFilters(currentFilters =>
            currentFilters.map((filter, idx) => {
                if (idx === index) {
                    return { ...filter, field: value, value: '' }; // Reset value when field changes
                }
                return filter;
            })
        );
    };

    const handleSelectChange = (selectedOption, index) => {
        setFilters(currentFilters => {
            const updatedFilters = [...currentFilters];
            updatedFilters[index].value = selectedOption ? selectedOption.value : '';
            return updatedFilters;
        });
    };

    const handleDateChange = (value, index, dateType) => {
        const currentDate = new Date().toISOString().split('T')[0];

        if (value > currentDate) {
            alert('A data não pode ser no futuro.');
            return;
        }

        setFilters(currentFilters => {
            const updatedFilters = [...currentFilters];
            if (!updatedFilters[index].value) {
                updatedFilters[index].value = { startDate: '', endDate: '' };
            }
            updatedFilters[index].value[dateType] = value;

            const { startDate, endDate } = updatedFilters[index].value;
            if (startDate && endDate && startDate > endDate) {
                alert('A data de fim não pode ser anterior à data de início.');
                updatedFilters[index].value[dateType] = '';
            }

            return updatedFilters;
        });
    };

    return (
        <>
            {isLoadingFilters ? (
                <Row>
                    <div className="loader-box">
                        <div className="loader-7"></div>
                    </div>
                </Row>
            ) : (
                <Form className="card-body row">
                    {filters.map((filter, index) => (
                        <div className="d-flex mt-2" key={index}>
                            <div className={filter.field ? 'col-5 mr-5' : 'col-4 mr-5'}>
                                <Input className="form-control" type="select" value={filter.field} onChange={e => setFilterField(e.target.value, index)}>
                                    <option disabled value="">Selecione...</option>
                                    {filterFields.map((filterField, key) => (
                                        <option key={key} value={filterField.value}>{filterField.description}</option>
                                    ))}
                                </Input>
                            </div>

                            {filter.field && (
                                <div className="col-6 mr-5">
                                    {filter.field === "appointmentType" && (
                                        <Input className="form-control" type="select" value={filter.value} onChange={e => setFilters(currentFilters => {
                                            const updatedFilters = [...currentFilters];
                                            updatedFilters[index].value = e.target.value;
                                            return updatedFilters;
                                        })}>
                                            <option disabled value="">Selecione...</option>
                                            <option value={"Consulta"}>{'Consulta'}</option>
                                            <option value={"Procedimento"}>{'Procedimento'}</option>
                                        </Input>
                                    )}
                                    {filter.field === "status" && (
                                        <Input className="form-control" type="select" value={filter.value} onChange={e => setFilters(currentFilters => {
                                            const updatedFilters = [...currentFilters];
                                            updatedFilters[index].value = e.target.value;
                                            return updatedFilters;
                                        })}>
                                            <option disabled value="">Selecione...</option>
                                            <option value={"waiting_analitica"}>{'AGUARDANDO ANALITICA'}</option>
                                            <option value={"analysis"}>{'PARA ANALISE'}</option>
                                            <option value={"checked_by_system"}>{'CONFERIDO PELO SISTEMA'}</option>
                                            <option value={"glosa_analysis"}>{'ANALISE DE GLOSA'}</option>
                                            <option value={"glosa_refusal"}>{'RECUSA DE GLOSA'}</option>
                                            <option value={"approved"}>{'APROVADO'}</option>
                                            <option value={"paid_for_resource_glosa"}>{'PAGO POR RECURSO DE GLOSA'}</option>
                                        </Input>
                                    )}
                                    {filter.field === "guideNumber" && (
                                        <Input type="text" value={filter.value} onChange={e => setFilters(currentFilters => {
                                            const updatedFilters = [...currentFilters];
                                            updatedFilters[index].value = e.target.value;
                                            return updatedFilters;
                                        })} />
                                    )}
                                    {filter.field === "health_insurance_id" && (
                                        <Select
                                            id={`health_insurance_${index}`}
                                            options={healthInsurances.map(insurance => ({ value: insurance.id, label: insurance.name }))}
                                            placeholder="Escolha um Convênio"
                                            value={
                                                filter.value
                                                    ? healthInsurances.map(insurance => ({ value: insurance.id, label: insurance.name })).find(insurance => insurance.value === filter.value)
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                            isClearable
                                        />
                                    )}
                                    {filter.field === "patient_id" && (
                                        <Select
                                            id={`patient_${index}`}
                                            options={patients.map(patient => ({ value: patient.id, label: patient.name }))}
                                            placeholder="Escolha um Paciente"
                                            value={
                                                filter.value
                                                    ? patients.map(patient => ({ value: patient.id, label: patient.name })).find(patient => patient.value === filter.value)
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                            isClearable
                                        />
                                    )}
                                    {filter.field === "doctor_id" && (
                                        <Select
                                            id={`doctor_${index}`}
                                            options={doctors.map(doctor => ({ value: doctor.id, label: doctor.name }))}
                                            placeholder="Escolha um Médico"
                                            value={
                                                filter.value
                                                    ? doctors.map(doctor => ({ value: doctor.id, label: doctor.name })).find(doctor => doctor.value === filter.value)
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                            isClearable
                                        />
                                    )}
                                    {filter.field === "clinic_id" && (
                                        <Select
                                            id={`clinic_${index}`}
                                            options={clinics.map(clinic => ({ value: clinic.id, label: clinic.name }))}
                                            placeholder="Escolha uma Clínica"
                                            value={
                                                filter.value
                                                    ? clinics.map(clinic => ({ value: clinic.id, label: clinic.name })).find(clinic => clinic.value === filter.value)
                                                    : null
                                            }
                                            onChange={(selectedOption) => handleSelectChange(selectedOption, index)}
                                            isClearable
                                        />
                                    )}
                                    {filter.field === "date" && (
                                        <div className="row">
                                            <div className="col-6">
                                                <Input
                                                    className="form-control digits"
                                                    type="date"
                                                    value={filter.value ? filter.value.startDate : ''}
                                                    placeholder="Data Início"
                                                    onChange={e => handleDateChange(e.target.value, index, 'startDate')}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Input
                                                    className="form-control digits"
                                                    type="date"
                                                    value={filter.value ? filter.value.endDate : ''}
                                                    placeholder="Data Fim"
                                                    onChange={e => handleDateChange(e.target.value, index, 'endDate')}
                                                />
                                            </div>

                                        </div>
                                    )}
                                </div>
                            )}
                            <div>
                                <Button color="primary" onClick={() => deleteFilterRow(index)} ><i className="fa fa-trash-o" /></Button>
                            </div>
                        </div>
                    ))}
                    <div className="mt-3">
                        <Button color="primary" className="mr-5" onClick={() => createFilterRow()}><i className="fa fa-plus" /></Button>
                        <Button color="primary" className="mr-5" onClick={() => {
                            setFilterOnAppointments(filters);
                            setIsFilter(true);
                            saveFilters(filters);
                        }}>Filtrar</Button>
                        <Button color="primary" onClick={() => clearFilter()}>Limpar</Button>
                    </div>
                </Form>
            )}
        </>
    );
};

export default FilterBilling;
